export const hamburger = () => {
  const ham = document.querySelector('.js-hamburger'); //js-hamburgerの要素を取得し、変数hamに格納
  const nav = document.querySelector('.js-nav'); //js-navの要素を取得し、変数navに格納
  
      ham.addEventListener('click', function () { //ハンバーガーメニューをクリックしたら
        ham.classList.toggle('active'); // ハンバーガーメニューにactiveクラスを付け外し
        nav.classList.toggle('active'); // ナビゲーションメニューにactiveクラスを付け外し
    });
    
    document.addEventListener("DOMContentLoaded", function () {
      ham.addEventListener("click", function () {
          const body = document.body;
          const bodyStyle = window.getComputedStyle(body);
      
          if (bodyStyle.overflow === "hidden") {
            body.style.height = "";
            body.style.overflow = "";
          } else {
            body.style.height = "100%";
            body.style.overflow = "hidden";
          }
        });
      });

      window.addEventListener('resize', function() {
        const body = document.body;
        const bodyStyle = window.getComputedStyle(body);
        if (window.innerWidth > 992) {
            ham.classList.remove('active');
            nav.classList.remove('active');
            if (bodyStyle.overflow === "hidden") {
              body.style.height = "";
              body.style.overflow = "";
            }
        }
      });

  const navLinks = nav.querySelectorAll('a');

  navLinks.forEach(function (navLink) {
    navLink.addEventListener('click', function () {
      const body = document.body;
      const bodyStyle = window.getComputedStyle(body);
      ham.classList.remove('active');
      nav.classList.remove('active');
      if (bodyStyle.overflow === "hidden") {
        body.style.height = "";
        body.style.overflow = "";
      }
    });
  });
}

